import React, { useRef, useEffect, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Box, Card, Tooltip } from '@material-ui/core'
import { ACEHighwayTranslationContext } from '../../contexts/ACEHighwayTranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'
import Switch from 'src/components/Switch'
import PartyInfoFormProperties from '../PartyInfoFormProperties'
import PAXLSTFormProperties from '../PAXLSTFormProperties'

const { button: { CngIconButton } } = components

const { initialValues } = PartyInfoFormProperties.formikProps

function CrewInformationSection(props) {
    const { setRequirePaxlst, requirePaxlst } = props
    const { setValue, getValues } = useFormContext();
    const { getTranslatedText } = useContext(ACEHighwayTranslationContext)

    const status = useWatch({ name: 'status' })
    const messageIndicator = useWatch({ name: 'messageIndicator' })
    const partyInfo = useWatch({ name: 'partyInfo' })
    const lastIndex = useRef(partyInfo.length - 1)


    // Push party info default value with crew type 'Driver' if there isn't any
    useEffect(() => {
        const { initialValues } = PartyInfoFormProperties.formikProps

        if (partyInfo.length === 0) {
            setValue('partyInfo', [{ ...initialValues, partyType: 'VW', _id: 1 }])
        } else {
            const hasDriver = partyInfo.findIndex(party => party.partyType === 'VW') !== -1

            if (!hasDriver) {
                const result = [...getValues('partyInfo')]
                result.push({ ...initialValues, partyType: 'VW' })

                setValue('partyInfo', result.map((party, index) => ({ ...party, _id: index + 1 })))
            }
        }
    }, [partyInfo])

    function handleAddPartyInfo(data) {
        const partyInfo = [...getValues('partyInfo'), { ...data, _id: ++lastIndex.current }]
        setValue('partyInfo', partyInfo)
    }

    function handleDeletePartyInfo(data) {
        const partyInfo = [...getValues('partyInfo')].filter((party) =>
            party.id ? party.id !== data.id : party._id !== data._id
        )

        setValue('partyInfo', partyInfo)
    }

    function paxlstRequiredAction() {
        setRequirePaxlst(true)
        setValue('paxLSTFlag', true)

        const { initialValues } = PAXLSTFormProperties.formikProps
        const paxlst = [{
            ...initialValues,
            _id: 0,
            partyType: 'FL',
            status: 'DR'
        }]

        setValue('paxLSTInfo', paxlst)

        if (!status || status === '1005') {
            console.log('Auto change message function to Multistep Add')
            setValue('messageFunction', '2')
        }
    }

    function paxlstNotRequiredAction() {
        setRequirePaxlst(false)
        setValue('paxLSTInfo', [])
        setValue('autoSubmitPaxLst', false)
        setValue('autoConfirmManifest', false)
        if (messageIndicator !== 'MULTI' || !status || status === '1005') {
            setValue('paxLSTFlag', false)
        }

        if (!status || status === '1005') {
            console.log('Auto change message function to Singlestep Add')
            setValue('messageFunction', '22')
        }
    }

    return (
        <Grid item xs={12}>
            <CngSection title='Crew Information'
              helperText={getTranslatedText('ACE_HIGHWAY_MANIFEST', 'crewSectionHelper')}
              alignItems='flex-end'
              id="crew-info"
            >
                <Grid container spacing={1}>
                    {partyInfo.map((party, index) => (
                        <Grid key={index} item xs={12}>
                            <Card variant='outlined'>
                                <Box padding={2}>
                                    <Grid container justify='space-between' spacing={1}>

                                        <Grid item xs={12}>
                                            <PartyInfoFormProperties.Fields index={index} />
                                        </Grid>

                                        <Grid item xs='auto'>
                                            <Grid container spacing={1}>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Add'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'plus']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo(initialValues)}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                <Grid item>
                                                    <Tooltip placement='bottom' title='Clone'>
                                                        <span>
                                                            <CngIconButton
                                                                icon={['fal', 'copy']} size='small' type='outlined'
                                                                onClick={() => handleAddPartyInfo({ ...party, id: undefined })}
                                                            />
                                                        </span>
                                                    </Tooltip>
                                                </Grid>

                                                {partyInfo.length > 1 &&
                                                    <Grid item>
                                                        <Tooltip placement='bottom' title='Delete'>
                                                            <span>
                                                                <CngIconButton
                                                                    icon={['fal', 'trash']} size='small' type='outlined'
                                                                    onClick={() => handleDeletePartyInfo(party)}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    </Grid>
                                                }

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Grid container style={{ paddingTop: '5px' }}>
                    <Grid item xs={6} md={2}>
                        <Switch fullWidth label={'Require Paxlst'} checked={requirePaxlst} onChange={(event) => {
                            if (event.target.checked) {
                                paxlstRequiredAction()
                            } else {
                                paxlstNotRequiredAction()
                            }
                        }}
                        />
                    </Grid>

                </Grid>

            </CngSection>
        </Grid>
    )
}

export default CrewInformationSection