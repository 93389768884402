import React, { useEffect, useRef, useState } from 'react'
import { components, constants, useServices, useTranslation, useWhiteLabelServiceSettings } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import AceHighwayShipmentKeys from 'src/constants/locale/key/AceHighwayShipment'
import AceHighwayManifestKeys from 'src/constants/locale/key/AceHighwayManifest'
import AceHighwayShipmentApiUrls from 'src/apiUrls/AceHighwayShipmentApiUrls'
import AceHighwayManifestApiUrls from 'src/apiUrls/AceHighwayManifestApiUrls'
import NaCodeMaintenanceApiURLs from 'src/apiUrls/NaCodeMaintenanceApiUrls'
import pathMap from 'src/paths/pathMap'
import ShipmentFormProperties from '../../shipment/FormProperties'
import AttachShipmentDialog from './AttachShipmentDialog'
import ShipmentDialog from './ShipmentDialog'
import PropagateDialog from './PropagateDialog'
import axios from 'axios'
import sweetalert from 'sweetalert'
import {
  Box,
  Grid,
  makeStyles,
  Select,
  Tooltip,
  Typography,
  useTheme,
  Chip
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import Table from '../../../../components/aciacehighway/Table'
import { getStatusMetadata } from '../../../../common/NACommon'
import ShipmentUploadDialog from './ShipmentUploadDialog'
import SetResponseMessageDialog from './SetResponseMessageDialog'
import SubmitAllShipmentMessageDialog from './SubmitAllShipmentMessageDialog'
import ConfirmDialog from '../../../common/ConfirmDialog'
import ChargeTypeDialog from '../../../common/ChargeTypeDialog'
import { FileForUserGetUserDetails, GetLoggedInUserPartyName } from 'src/common/FileForUserCommon'
import ErrorMessageDialog from 'src/views/common/ErrorMessageDialog'

const {
  button: { CngButton, CngIconButton },
  table: { useFetchCodeMaintenanceLookup }
} = components

const {
  filter: { EQUAL, LIKE, IN }
} = constants

const useStyles = makeStyles((theme) => ({
  select: {
    backgroundColor: theme.name === 'ONE_DARK' ? 'transparent' : theme.palette.common.white,
    fontSize: 14,
    maxWidth: '100%',
    width: 150,
    '& > .MuiOutlinedInput-input': {
      padding: 8
    },
    '& > .MuiSelect-outlined.MuiSelect-outlined': {
      paddingRight: 32
    }
  }
}))

function ShipmentAttachTable(props) {
  const { history, loading, manifestId, onChangeStep, onSetLoading, showNotification, step, port, manifestStatus, manifestCarrierScac } = props

  const [lookups, setLookups] = useState(null)
  const [amendmentCodeOptions, setAmendmentCodeOptions] = useState([])
  const [attachShipmentDialog, setAttachShipmentDialog] = useState(false)
  const [shipmentDialog, setShipmentDialog] = useState({
    open: false,
    shipment: null,
    isView: false
  })
  const [propagateDialog, setPropagateDialog] = useState({
    open: false,
    propagateShipments: [],
    isView: false
  })
  const [setResponseMsgDialog, setSetResponseMsgDialog] = useState({ open: false, shipments: [] })
  const [submitAllShipmentMsgDialog, setSubmitAllShipmentMsgDialog] = useState({ open: false })
  const [uploadShipmentDialog, setUploadShipmentDialog] = useState({ open: false, tableRef: null })
  const [confirmDialog, setConfirmDialog] = useState({ open: false, shipment: null })
  const [massDeleteConfirmDialog, setMassDeleteConfirmDialog] = useState({ open: false, idList: null })
  const [errorMessageDialog, setErrorMessageDialog] = useState({ open: false, errorMessages: [] })

  const tableRef = useRef(null)
  const { createRecord, deleteRecord, fetchRecords, securedSendRequest, updateRecord } = useServices()
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const theme = useTheme()

  const [chargeTypes, setChargeTypes] = useState([])
  const [submitDialog, setSubmitDialog] = useState({ open: false, shipments: [] })

  const [isSuperParty, setSuperParty] = useState(false);
  const [isMassSubmit, setMassSubmit] = useState(false);
  const [topBarAction, setTopBarAction] = useState([])
  const [selectTopBarAction, setSelectTopBarAction] = useState([])
  const whiteLabelServiceSettings = useWhiteLabelServiceSettings("ace-highway")

  useEffect(() => {
    Promise.all([
      // Status
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_STATUS' }],
        undefined,
        'code'
      ),
      // Countries
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: EQUAL,
            value: 'ACIHWY_MANIFEST_COUNTRY'
          }
        ],
        undefined,
        'code'
      ),
      // Country states
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET,
        {
          filters: [{ field: 'indicator', operator: EQUAL, value: 'ACE' }],
          customData: { codeMType: 'CountryStateCode' }
        },
        (res) => res.content
      ),
      // Party type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'ACE_HW_PARTY_TYPE' }],
        undefined,
        'code'
      ),
      // Entry type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [
          {
            field: 'codeType',
            operator: IN,
            value: 'ACE_HW_ENTRY_TYPE_IN_BOND|ACE_HW_ENTRY_TYPE_SEC'
          }
        ],
        undefined,
        'code'
      ),
      // Amendment codes
      fetchRecords.execute(
        NaCodeMaintenanceApiURLs.GET_NOT_IN_NA,
        {
          customData: { codeMType: constants.CodeMaintenanceType.CODE_MASTER },
          filters: [
            {
              field: 'active',
              operator: EQUAL,
              value: 'true'
            },
            {
              field: 'codeType',
              operator: EQUAL,
              value: 'ACE_HW_SHIPMENT_AMENDMENT_CODE'
            }
          ]
        },
        (response) => {
          return response.content.map((status) => ({
            code: status.code,
            desc: status.descriptionEn
          }))
        },
        () => {
          showNotification('error', 'Failed to fetch Amendment Code options')
        }
      ),
      // Charge type
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_CHARGE_TYPE' }],
        undefined,
        'code'
      ),
      // Super party
      fetchCodeMaintenanceLookup(
        constants.CodeMaintenanceType.CODE_MASTER,
        undefined,
        [{ field: 'codeType', operator: EQUAL, value: 'BILLING_SUPER_PARTY' }],
        undefined, 'code'
      )
    ]).then(
      ([
        status,
        countries,
        countryStates,
        partyType,
        entryType,
        amendmentCodes,
        billingChargeTypes,
        billingSuperParty
      ]) => {
        const states = Object.keys(countries).reduce((acc, countryCode) => {
          acc[countryCode] = countryStates.filter(
            (state) => state.code === countryCode
          )

          return acc
        }, {})

        setLookups({
          status,
          countries,
          countryStates: states,
          partyType,
          entryType
        })

        setAmendmentCodeOptions(amendmentCodes)
        compileChargeTypes(billingChargeTypes)

        Object.keys(billingSuperParty).forEach(e => {
          if (e == GetLoggedInUserPartyName()) {
            setSuperParty(true);
          }
        })
      }
    )

    if (whiteLabelServiceSettings.length > 0) {
      let shipmentPropagate = whiteLabelServiceSettings[0].custom.UI_SHIPMENT_ATTACH_PROPAGATE
      if (shipmentPropagate == "true") {
        setTopBarAction(customTopBarAction);
        setSelectTopBarAction(customSelectTopBarAction);
      } else {
        setTopBarAction(defaultTopBarAction);
        setSelectTopBarAction(defaultSelectTopBarAction);
      }
    } else {
      setTopBarAction(defaultTopBarAction);
      setSelectTopBarAction(defaultSelectTopBarAction);
    }
    
  }, [])

  useEffect(() => {
    if(isSuperParty){
      setTopBarAction(customTopBarAction);
      setSelectTopBarAction(customSelectTopBarAction);
    }
  },[isSuperParty]);

  function compileChargeTypes(billingChargeTypes) {
    var jsonArray = [];
    if (billingChargeTypes) {
      Object.entries(billingChargeTypes).forEach((e) => {
        var jsonObject = { code: e[0], desc: e[1] }
        jsonArray.push(jsonObject)
      })
    }
    setChargeTypes(jsonArray)
  }

  const { translate } = useTranslation([
    Namespace.ACE_HIGHWAY_SHIPMENT,
    Namespace.ACE_HIGHWAY_MANIFEST
  ])

  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let scn = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SCN
    )
    let shipmentType = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPMENT_TYPE
    )
    let acquittalNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ACQUITTAL_NO
    )
    let pointOfLoading = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.POINT_OF_LOADING
    )
    let quantity = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.QUANTITY
    )
    let shipperName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SHIPPER_NAME
    )
    let consigneeName = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CONSIGNEE_NAME
    )
    let createdDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CREATED_DATE
    )
    let updatedDate = translate(
      Namespace.ACE_HIGHWAY_MANIFEST,
      AceHighwayManifestKeys.UPDATED_DATE
    )
    let responseDate = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.RESPONSE_DATE
    )
    let status = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.STATUS
    )
    let submitSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_SUCCESS_MESSAGE
    )
    let editButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.EDIT_BUTTON
    )
    let cloneButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CLONE_BUTTON
    )
    let deleteButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DELETE_BUTTON
    )
    let detachButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DETACH_BUTTON
    )
    let submitButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_BUTTON
    )
    let detachSuccessMsg = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DETACH_SUCCESS_MSG
    )
    let detachSentRecord = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.DETACH_SENT_REC
    )
    let cantEditST = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CANT_EDIT_ST_RECORD
    )
    let submitSentRecord = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_SENT_RECORD
    )
    let cantSubmitDifferentShipmentTypeTogether = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CANT_SUBMIT_DIFF_SHIP_TYPE
    )
    let cancelButton = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CANCEL_BUTTON
    )
    let messageFunction = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MESSAGE_FUNCTION
    )
    let msgFuncAdd = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MESSAGE_FUNCTION_ADD
    )
    let msgFuncModify = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MESSAGE_FUNCTION_MODIFY
    )
    let msgFuncCancel = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MESSAGE_FUNCTION_CANCEL
    )
    let msgFuncDelink = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.MESSAGE_FUNCTION_DELINK
    )
    let amendmentCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.AMENDMENT_CODE
    )
    let submitSuccessMessage = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_SUCCESS_MESSAGE
    )
    let submitErrorMessage = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_ERROR_MESSAGE
    )
    let submitEmptyAmendmentCode = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.SUBMIT_EMPTY_AMENDMENT_CODE
    )
    let customsBroker = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.CUSTOMS_BROKER
    )
    let brokerEntryNo = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.BROKER_ENTRY_NO
    )
    let attachShipmentSectionHelper = translate(
      Namespace.ACE_HIGHWAY_SHIPMENT,
      AceHighwayShipmentKeys.ATTACH_SHIPMENT_SECTION_HELPER
    )

    return {
      scn,
      shipmentType,
      acquittalNo,
      pointOfLoading,
      quantity,
      shipperName,
      consigneeName,
      createdDate,
      updatedDate,
      responseDate,
      status,
      submitSuccessMsg,
      editButton,
      cloneButton,
      deleteButton,
      detachButton,
      submitButton,
      detachSuccessMsg,
      detachSentRecord,
      cantEditST,
      submitSentRecord,
      cantSubmitDifferentShipmentTypeTogether,
      cancelButton,
      messageFunction,
      msgFuncAdd,
      msgFuncModify,
      msgFuncCancel,
      msgFuncDelink,
      amendmentCode,
      submitSuccessMessage,
      submitErrorMessage,
      submitEmptyAmendmentCode,
      customsBroker,
      brokerEntryNo,
      attachShipmentSectionHelper
    }
  }

  function getLookupValue(name, value) {
    if (!lookups) return value

    return lookups[name] && lookups[name][value] ? lookups[name][value] : value
  }

  function getCountryStateLabel(country, state) {
    if (!lookups || !country || !state) return state

    // Check if country code exists in the lookup
    if (Object.keys(lookups.countries).includes(country)) {
      const result = lookups.countryStates[country].find(
        (countryState) => countryState.intlcode === state
      )

      return result ? result.descriptionEn : state
    } else {
      return state
    }
  }

  const columns = [
    {
      title: "Sno"
    },
    {
      field: 'scn',
      sortKey: 'scn',
      title: `${translatedTextsObject.scn} / ${translatedTextsObject.shipmentType} / ${translatedTextsObject.acquittalNo}`,
      render: (rowData) => (
        <Box>
          <Typography component='div' variant='inherit'>
            {rowData.scn}
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            {[rowData.shipmentType, rowData.acquittalNo].join(' • ')}
          </Typography>
        </Box>
      )
    },
    {
      field: 'shipperName',
      sortKey: 'shipperName',
      title: translatedTextsObject.shipperName
    },
    {
      field: 'consigneeName',
      sortKey: 'consigneeName',
      title: translatedTextsObject.consigneeName
    },
    {
      field: 'status',
      sortKey: 'status',
      title: translatedTextsObject.status,
      render: (data) => {
        const status = getStatusMetadata(data.status)

        if (data.status == '1000' && data.shipmentType == 'REGULAR' && !data.brokerEntryNo) {
          status.color = "#ffc400"
          status.contrastColor = "#000000"
        }

        return (
          <Box display='flex' flexDirection='row' flexWrap='wrap' style={{ gap: 4 }}>
            <Chip
              label={status.label}
              size='small'
              style={{ color: status.contrastColor, backgroundColor: status.color, fontSize: 12 }}
            />

            {('REGULAR' == data.shipmentType && data.eventLocation && data.eventLocation != port) &&
              <Chip key='first' label={"Port Mismatch"} size='small'
                style={{ color: '#ffffff', backgroundColor: '#DF2901', fontSize: 12 }}
              />
            }
          </Box>
        )
      }
    },
    {
      title: 'Response message / Amendment code',
      render: (rowData) => {
        const selectedRows = tableRef.current.selectedRows
        const isSelected =
          selectedRows.length > 0
            ? selectedRows.map((rowData) => rowData.id).includes(rowData.id)
            : false

        if (!rowData.messageFunction) {
          rowData.messageFunction = '23'
        }

        return (
          <MessageFuncAndAmendmentCode
            amendmentCodeOptions={amendmentCodeOptions}
            isSelected={isSelected}
            isSentStatus={rowData.status === '1008'}
            onUpdateShipment={handleModifyShipment}
            onSubmitShipment={(data) => preSubmitShipments([data])}
            shipment={rowData}
            translatedTextsObject={translatedTextsObject}
            manifestStatus={manifestStatus}
          />
        )
      }
    },
    {
      field: 'brokerEntryNo',
      sortKey: 'brokerEntryNo',
      title: translatedTextsObject.brokerEntryNo,
      render: (rowData) => {
        if ('IN-BOND' != rowData.shipmentType) {
          return rowData.brokerEntryNo
        }
      }
    },
    {
      field: 'customsBroker',
      sortKey: 'customsBroker',
      title: translatedTextsObject.customsBroker,
    },
  ]

  const templateTopBarAction = [
    {
      buttonProps: {
        color: 'secondary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => setShipmentDialog({ open: true, shipment: null, isView: false })
      },
      label: 'Create new shipment'
    }
  ]

  const defaultTopBarAction = [
    {
      buttonProps: {
        color: 'secondary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
        onClick: () => setSubmitAllShipmentMsgDialog({ open: true }),
      },
      label: 'Submit All Shipments'
    },
    {
      buttonProps: {
        color: 'secondary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'upload']} />,
        onClick: () => setUploadShipmentDialog({ open: true, tableRef: tableRef })
      },
      label: 'Upload new shipment'
    },
    {
      buttonProps: {
        color: 'secondary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'plus-circle']} />,
        onClick: () => setShipmentDialog({ open: true, shipment: null, isView: false })
      },
      label: 'Create new shipment'
    },
    {
      buttonProps: {
        color: 'primary',
        size: 'medium',
        startIcon: <FontAwesomeIcon icon={['fal', 'link']} />,
        onClick: () => setAttachShipmentDialog(true)
      },
      label: 'Attach shipment'
    }
  ]

  const customTopBarAction = [
    {
      buttonProps: {
        color: 'secondary',
        size: 'medium',
        startIcon: <ReplyAllIcon icon={['fal', 'arrow-alt-right']} />,
        onClick: () => setPropagateDialog({ open: true }),
      },
      label: 'Propagate'
    }
  ]

  if (manifestStatus === '1002') {
    customTopBarAction.push(...templateTopBarAction)
  } else {
    customTopBarAction.push(...defaultTopBarAction)
  }

  const defaultSelectTopBarAction = [
      {
        buttonProps: {
          color: 'primary',
          size: 'medium',
          variant: 'outlined',
          style: { minWidth: 'auto' }
        },
        disabled: (rows) => {
          let shouldDisable = false
          let isSplit = false
          let isNotSplit = false
          rows.forEach((rowData) => {
            if (rowData.status === '1008' || rowData.status === '1002') {
              shouldDisable = true
            }

            if (rowData.messageFunction === '24' && ('' === rowData.amendmentCode || null === rowData.amendmentCode)) {
              shouldDisable = true
            }

            if (!rowData.messageFunction) {
              shouldDisable = true
            }

            if(rowData.splitIndicator){
              isSplit = true
            }else{
              isNotSplit = true
            }
            if(isSplit && isNotSplit){
              shouldDisable = true
            }
          })

          return shouldDisable
        },
        icon: <FontAwesomeIcon icon={['fal', 'arrow-alt-right']} />,
        label: translatedTextsObject.submitButton,
        onClick: (rows) => preSubmitShipments(rows),
        tooltip: (rows) => {
          let includesSentRecords = false
          let missingAmendmentCode = false

          rows.forEach((rowData) => {
            if (rowData.status === '1008') {
              includesSentRecords = true
            }

            if (rowData.messageFunction === '24' && ('' === rowData.amendmentCode || null === rowData.amendmentCode)) {
              missingAmendmentCode = true
            }
          })

          return includesSentRecords
            ? translatedTextsObject.submitSentRecord
            : missingAmendmentCode ? translatedTextsObject.submitEmptyAmendmentCode : null
        }
      },
      {
        buttonProps: {
          color: 'primary',
          size: 'medium',
          variant: 'outlined',
          style: { minWidth: 'auto' }
        },
        disabled: (rows) => {
          let shouldDisable = false
          let isSplit = false
          let isNotSplit = false
          rows.forEach((rowData) => {
            if (rowData.status === '1008' || rowData.status === '1002') {
              shouldDisable = true
            }

            if(rowData.splitIndicator){
              isSplit = true
            }else{
              isNotSplit = true
            }
            if(isSplit && isNotSplit){
              shouldDisable = true
            }
          })

          return shouldDisable
        },
        icon: <FontAwesomeIcon icon={['fal', 'cog']} />,
        label: 'Set response message',
        onClick: (rows) => setSetResponseMsgDialog({ open: true, shipments: rows }),
        tooltip: (rows) => {
          let includesSentRecords = false

          rows.forEach((rowData) => {
            if (rowData.status === '1008') {
              includesSentRecords = true
            }
          })

          return includesSentRecords ? translatedTextsObject.submitSentRecord : null
        }
      },
      {
        buttonProps: {
          color: 'primary',
          size: 'medium',
          variant: 'outlined',
          style: { minWidth: 'auto' }
        },
        icon: <FontAwesomeIcon icon={['fal', 'arrow-to-bottom']} />,
        label: 'Export',
        onClick: () => { }
      },
    {
      disabled: (rows) => {
        let disable = false;
        rows.forEach(e => {
          if (e.status !== '1005') { disable = true }
        })
        return disable;
      },
      icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
      label: "Mass Delete",
      tooltip: (rows) => {
        let description = null;
        rows.forEach(e => {
          if (e.status !== '1005') { description = 'Only draft status can be deleted' }
        })
        return description;
      },
      buttonProps: {
        size: 'medium',
        color: 'secondary',
        style: { color: theme.palette.error.main, minWidth: 'auto' }
      },
      onClick: (rows) => {
        let idList = rows.map(e => e.id)
        setMassDeleteConfirmDialog({ open: true, idList: idList });
      }
    },
    {
        buttonProps: {
        size: 'medium',
        color: 'secondary',
        style: { color: theme.palette.error.main, minWidth: 'auto' }
        },
        disabled: (rows) => {
          let shouldDisable = false

          rows.forEach((rowData) => {
            if (
              rowData.status === '1000' ||
              rowData.status === '1002' ||
              rowData.status === '1008' ||
              rowData.status === '1007' ||
              rowData.status === '1004'
            ) {
              shouldDisable = true
            }
          })

          return shouldDisable
        },
        icon: <FontAwesomeIcon icon={['fal', 'minus-circle']} />,
        label: translatedTextsObject.detachButton,
        onClick: handleDetachShipments,
        tooltip: (rows) => {
          let includesSentRecords = false

          rows.forEach((rowData) => {
            if (
              rowData.status === '1000' ||
              rowData.status === '1008' ||
              rowData.status === '1007' ||
              rowData.status === '1004'
            ) {
              includesSentRecords = true
            }
          })

          return includesSentRecords ? translatedTextsObject.detachSentRecord : null
        }
      }
  ]

  const customSelectTopBarAction = [
      {
        buttonProps: {
          color: 'primary',
          size: 'medium',
          variant: 'outlined',
          style: { minWidth: 'auto' }
        },
        disabled: (rows) => {
          let shouldDisable = false
          rows.forEach((rowData) => {
            if (rowData.status === '1008') {
              shouldDisable = true
            }
          })

          return shouldDisable
        },
        icon: <ReplyAllIcon icon={['fal', 'cog']} />,
        label: 'Propagate',
        onClick: (rows) => setPropagateDialog({ open: true, propagateShipments: rows, isView: false }),
        // tooltip: (rows) => {
        //   let includesSentRecords = false

        //   rows.forEach((rowData) => {
        //     if (rowData.status === '1008') {
        //       includesSentRecords = true
        //     }
        //   })

        //   return includesSentRecords
        //     ? translatedTextsObject.submitSentRecord
        //     : null
        // }
      }
  ]

  if (manifestStatus !== '1002') {
    customSelectTopBarAction.push(...defaultSelectTopBarAction)
  }

  function handleCreateShipment(data) {
    onSetLoading(true)
    createRecord.execute(
      AceHighwayShipmentApiUrls.POST,
      ShipmentFormProperties.toServerDataFormat(data),
      () => {
        tableRef.current.performRefresh()

        setShipmentDialog({ open: false, shipment: null, isView: false })
        showNotification('success', 'Shipment created')
      },
      (error) => {
        const { errorMessages } = error.response.data

        errorMessages.forEach((message) => {
          showNotification('error', message)
        })
      }
    ).finally(() => {
      onSetLoading(false)
    })
  }

  function handleEditShipment(data) {
    onSetLoading(true)
    updateRecord.execute(
      AceHighwayShipmentApiUrls.PUT,
      ShipmentFormProperties.toServerDataFormat(data),
      () => {
        tableRef.current.performRefresh()

        setShipmentDialog({ open: false, shipment: null, isView: false })
        showNotification('success', 'Shipment updated.')
      },
      (error) => {
        if (error.response.data.errorMessages) {
          error.response.data.errorMessages.forEach((errorMessage) => {
            showNotification('error', errorMessage)
          })
        }
      }
    ).finally(() => {
      onSetLoading(false)
    })
  }

  function handlePropagateUpdateShipment(data) {
    if (data.aceHighwayShipmentHeaders !== undefined ? !window.confirm("Propagate data will be applied to the below selected shipments. Do you wish to continue?") : !window.confirm("Shipments not selected, the data will be propogated to all the shipments of this manifest! Do you wish to continue?")) {
      return;
    } else {
      updateRecord.execute(
        AceHighwayShipmentApiUrls.UPDATE_PROPAGATE_SHIPMENT_INFO,
        ShipmentFormProperties.toServerDataFormat(data),
        () => {
          tableRef.current.performRefresh()

          setPropagateDialog({ open: false, propagateShipments: [], isView: false })
          showNotification('success', 'Propagate shipment/s updated.')
        },
        (error) => {
          if (error.response.data.errorMessages) {
            error.response.data.errorMessages.forEach((errorMessage) => {
              showNotification('error', errorMessage)
            })
          }
        }
      )
    }
  }

  function handleCloneShipment(data) {
    createRecord.execute(
      AceHighwayShipmentApiUrls.CLONE,
      data,
      (res) => {
        res.manifestId = manifestId

        history.push({
          pathname: `${pathMap.ACE_HIGHWAY_SHIPMENT}/add`,
          state: res
        })
      },
      (error) => {
        console.log(error)
      }
    )
  }

  function handleDetachShipments(rows) {
    const result = {
      manifestId,
      shipmentId: rows.map((shipment) => shipment.id)
    }

    const instance = axios.create({
      withCredentials: false,
      headers: {
        Authorization:
          'Bearer ' + JSON.parse(localStorage.getItem('userToken')),
        'Content-Type': 'application/json'
      }
    })

    instance
      .post(AceHighwayManifestApiUrls.DETACH, JSON.stringify(result))
      .then((res) => {
        const { errorMessages } = res.data

        if (res.status === 200 && errorMessages === null) {
          tableRef.current.performRefresh()

          showNotification('success', translatedTextsObject.detachSuccessMsg)
        } else {
          showNotification('error', errorMessages)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  function handleDeleteShipment() {
    if (confirmDialog.shipment) {
      deleteRecord.execute(
        AceHighwayShipmentApiUrls.DELETE,
        confirmDialog.shipment,
        () => {
          showNotification('success', 'Delete succeeded')
          setConfirmDialog({ open: false, shipment: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }

  function handleModifyShipment(shipments) {
    try {
      let inbondAdCode = amendmentCodeOptions
        .filter(
          (option) =>
            (shipments.shipmentType === 'IN-BOND' && option.code > 15) ||
            (shipments.shipmentType !== 'IN-BOND' &&
              option.code !== '16' &&
              option.code !== '17' &&
              option.code !== '26')
        )

      if (shipments.messageFunction === '24') {
        if (shipments.amendmentCode === null || (shipments.shipmentType === "IN-BOND" && undefined === inbondAdCode.find(code => code.code === shipments.amendmentCode))) {
          shipments.amendmentCode = (shipments.status === '1000' ? (shipments.shipmentType !== "IN-BOND" ? '03' : '16') : '')
        }
      } else {
        shipments.amendmentCode = null
      }

      if (tableRef.current) {
        tableRef.current.updateData(shipments)
      }
    } catch (error) {
      console.log(error)
    }
  }

  function preSubmitShipments(shipments) {
    if (isSuperParty) {
      setMassSubmit(false)
      setSubmitDialog({ open: true, shipments: shipments })
    } else {
      shipments.forEach(e => {
        e.chargeAs = 'REGULAR'
      })
      handleSubmitShipments(shipments)
    }
  }

  function handleSubmitShipments(shipments) {
    try {
      tableRef.current.setLoading(true)
      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        shipments.forEach(data => {
          data.fileForUserId = fileForUserDetails.fileForUserId;
          data.fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
          data.fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
        });
      }

      securedSendRequest.execute('POST', AceHighwayShipmentApiUrls.SUBMIT, shipments,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }
          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.submitErrorMessage)
        },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function preSubmitAllShipment(data) {
    if (isSuperParty) {
      setMassSubmit(true)
      setSubmitDialog({ open: true, shipments: data })
    } else {
      data.chargeAs = 'REGULAR'
      submitAllShipments(data)
    }
  }

  function submitAllShipments(data) {
    try {
      tableRef.current.setLoading(true)

      const formData = new FormData()
      formData.append('manifestId', data.manifestId)
      formData.append('status', data.status)
      formData.append('chargeType', data.chargeAs)

      let fileForUserDetails = FileForUserGetUserDetails();
      if (fileForUserDetails != null && fileForUserDetails != undefined) {
        formData.append("fileForUserDetails", JSON.stringify(fileForUserDetails));
      } else {
        formData.append("fileForUserDetails", "{}");
      }

      securedSendRequest.execute(
        'POST',
        AceHighwayShipmentApiUrls.SUBMIT_ALL,
        formData,
        (response) => {
          const data = response.data
          var errorMessages = []
          var errorFlag = false
          for (var key in data) {
            var obj = data[key]
            if (obj.errorMessages != null && obj.errorMessages.length > 0) {
              errorMessages.push(obj.errorMessages)
              errorFlag = true
            }
          }

          if (errorFlag) {
            setErrorMessageDialog({ open: true, errorMessages })
          } else {
            showNotification('success', translatedTextsObject.submitSuccessMessage)
            tableRef.current.performRefresh()
          }
        },
        error => {
          console.log(error)
          showNotification('error', translatedTextsObject.submitErrorMessage)
        },
        () => tableRef.current.setLoading(false)
      )
    } catch (error) {
      console.log(error)
    }
  }

  function hasINBONDShipmentType(rows) {
    const shipmentTypes = rows.map((object) => object.shipmentType)
    var unique = shipmentTypes.filter((v, i, a) => a.indexOf(v) === i)

    return unique.length > 1 && unique.includes('IN-BOND')
  }

  function handleMassDeleteShipment() {
    if (massDeleteConfirmDialog.idList) {
      setMassDeleteConfirmDialog({ open: false })
      sweetalert("Shipment deletion in progress...", "", "success");
      securedSendRequest.execute('POST',
        AceHighwayShipmentApiUrls.MASS_DELETE,
        massDeleteConfirmDialog.idList,
        () => {
          showNotification('success', 'Mass delete succeeded')
          setMassDeleteConfirmDialog({ open: false, idList: null })
          tableRef.current.performRefresh()
        },
        (error) => {
          showNotification('error', 'Something went wrong. Mass delete failed.')
          console.log(error)
        }
      )
    }
  }

  return (
    <>
      <Table
        actions={topBarAction}
        checkboxSelection
        columns={columns}
        compact
        customRowActions={[
          {
            disabled: (rowData) => rowData.status === '1002',
            iconButtonProps: {
              icon: ['fal', 'minus-circle'],
              style: { color: theme.palette.error.main }
            },
            onClick: (rowData) => handleDetachShipments([rowData]),
            tooltip: (rowData) =>
              rowData.status !== '1002'
                ? translatedTextsObject.detachButton :
                'Shipment cannot be detached'
          }
        ]}
        fetch={{ url: AceHighwayShipmentApiUrls.CUSTOM_SEARCH }}
        fetchFilters={[
          { field: 'headerId', operator: EQUAL, value: manifestId }
        ]}
        onRowClick={(rowData) =>
          setShipmentDialog({ open: true, shipment: rowData, isView: true })
        }
        rowActions={[
          {
            disabled: (rowData) => rowData.status === '1008',
            icon: <FontAwesomeIcon icon={['fal', 'pen']} />,
            label: translatedTextsObject.editButton,
            onClick: (rowData) =>
              setShipmentDialog({
                open: true,
                shipment: rowData,
                isView: false
              }),
            tooltip: (rowData) =>
              rowData.status === '1008'
                ? translatedTextsObject.cantEditST
                : null
          },
          {
            disabled: (rowData) => rowData.status === '1002',
            icon: <FontAwesomeIcon icon={['fal', 'copy']} />,
            label: translatedTextsObject.cloneButton,
            onClick: handleCloneShipment
          },
          {
            disabled: (rowData) => rowData.status !== '1005',
            icon: <FontAwesomeIcon icon={['fal', 'trash']} />,
            label: translatedTextsObject.deleteButton,
            onClick: (rowData) => setConfirmDialog({ open: true, shipment: rowData }),
            tooltip: (rowData) =>
              rowData.status !== '1005'
                ? 'Only draft status can be deleted'
                : null
          }
        ]}
        selectActions={selectTopBarAction}
        search={{ field: 'scn', operator: LIKE }}
        showNotification={showNotification}
        tableRef={tableRef}
        helperText={translatedTextsObject.attachShipmentSectionHelper}
      />
      <Box marginTop={2}>
        <Grid container justify='space-between' spacing={3}>
          <Grid item xs={12} md='auto'>
            <CngButton
              color='secondary'
              onClick={() =>
                history.push(pathMap.ACE_HIGHWAY_MANIFEST_LIST_VIEW)
              }
              size='medium'
            >
              Discard
            </CngButton>
          </Grid>
          <Grid item xs={12} md='auto'>
            <Grid container spacing={2}>
              <Grid item xs='auto'>
                <CngButton
                  color='secondary'
                  onClick={() => onChangeStep(step - 1)}
                  size='medium'
                >
                  Back
                </CngButton>
              </Grid>
              <Grid item xs='auto'>
                <CngButton color='primary' onClick={() => onChangeStep(step + 1)} size='medium'>
                  Add Status Notification (Optional)
                </CngButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <AttachShipmentDialog
        columns={columns}
        manifestId={manifestId}
        open={attachShipmentDialog}
        onAttachShipment={() => {
          if (tableRef.current) {
            tableRef.current.performRefresh()
          }
        }}
        onClose={() => setAttachShipmentDialog(false)}
        showNotification={showNotification}
      />
      <ShipmentDialog
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
        isView={shipmentDialog.isView}
        loading={loading}
        manifestId={manifestId}
        firstExpectedCode={port}
        onClose={() =>
          setShipmentDialog({ open: false, shipment: null, isView: false })
        }
        onCreateShipment={handleCreateShipment}
        onEditShipment={handleEditShipment}
        open={shipmentDialog.open}
        shipment={shipmentDialog.shipment}
        showNotification={showNotification}
        manifestStatus={manifestStatus}
        manifestCarrierScac={manifestCarrierScac}
      />
      <SubmitAllShipmentMessageDialog
        manifestId={manifestId}
        onClose={() => setSubmitAllShipmentMsgDialog({ open: false })}
        onSubmitShipment={preSubmitAllShipment}
        open={submitAllShipmentMsgDialog.open}
        translatedTextsObject={translatedTextsObject}
      />
      <SetResponseMessageDialog
        amendmentCodeOptions={amendmentCodeOptions}
        onClose={() => setSetResponseMsgDialog({ open: false, shipments: [] })}
        onSaveShipment={(shipments) => {
          handleModifyShipment(shipments)
          setSetResponseMsgDialog({ open: false, shipments: [] })
        }}
        onSubmitShipment={preSubmitShipments}
        open={setResponseMsgDialog.open}
        shipments={setResponseMsgDialog.shipments}
        translatedTextsObject={translatedTextsObject}
      />
      <ShipmentUploadDialog
        manifestId={manifestId}
        open={uploadShipmentDialog}
        onClose={() => setUploadShipmentDialog({ open: false, tableRef: null })}
        showNotification={showNotification}
      />
      <PropagateDialog
        getCountryStateLabel={getCountryStateLabel}
        getLookupValue={getLookupValue}
        isView={propagateDialog.isView}
        loading={loading}
        manifestId={manifestId}
        onClose={() =>
          setPropagateDialog({ open: false, propagateShipments: [], isView: false })
        }
        handlePropagateUpdateShipment={handlePropagateUpdateShipment}
        open={propagateDialog.open}
        showNotification={showNotification}
        propagateShipments={propagateDialog.propagateShipments}
      />
      <ConfirmDialog
        isConfirmDialogOpen={confirmDialog.open}
        closeDialog={() => setConfirmDialog({ open: false, shipment: null })}
        confirmDialog={handleDeleteShipment}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ConfirmDialog
        isConfirmDialogOpen={massDeleteConfirmDialog.open}
        closeDialog={() => setMassDeleteConfirmDialog({ open: false, idList: null })}
        confirmDialog={handleMassDeleteShipment}
        content="Items that you delete can't be restored. Are you sure about this?"
        okMsg='Yes, delete'
        cancelMsg='No, take me back'
        title={translatedTextsObject.deleteButton}
      />
      <ChargeTypeDialog
        chargeTypes={chargeTypes}
        onClose={() => setSubmitDialog({ open: false, shipments: [] })}
        onSubmit={isMassSubmit ? submitAllShipments : handleSubmitShipments}
        open={submitDialog.open}
        items={submitDialog.shipments}
      />
      <ErrorMessageDialog
        errorMessages={errorMessageDialog.errorMessages}
        open={errorMessageDialog.open}
        onClose={() => setErrorMessageDialog({ open: false })} />
    </>
  )
}

export default ShipmentAttachTable

function MessageFuncAndAmendmentCode(props) {
  const {
    amendmentCodeOptions,
    isSelected,
    isSentStatus,
    onSubmitShipment,
    shipment,
    onUpdateShipment,
    translatedTextsObject,
    manifestStatus
  } = props

  const { shipmentType, splitIndicator } = shipment
  const theme = useTheme()
  const classes = useStyles()

  function handleSubmitButtonClick(event) {
    event.stopPropagation()
    onSubmitShipment(shipment)
  }

  return (
    <Grid alignItems='center' container spacing={1} wrap='nowrap'>
      <Grid item xs='auto'>
        <Select
          className={classes.select}
          disabled={isSentStatus || !isSelected}
          native
          onChange={(event) =>
            onUpdateShipment({ ...shipment, messageFunction: event.target.value })
          }
          onClick={(event) => event.stopPropagation()}
          size='small'
          value={shipment.messageFunction || ''}
          variant='outlined'
        >
          <option value="" disabled>
            {translatedTextsObject.messageFunction}
          </option>
          <option value='23'>{translatedTextsObject.msgFuncAdd}</option>
          {!splitIndicator && <option value='24'>{translatedTextsObject.msgFuncModify}</option>}
          <option value='22'>{splitIndicator ? translatedTextsObject.msgFuncDelink : translatedTextsObject.msgFuncCancel}</option>
        </Select>
      </Grid>
      {shipment.messageFunction === '24' && (
        <Grid item xs='auto'>
          <Select
            className={classes.select}
            disabled={isSentStatus || !isSelected}
            native
            onChange={(event) =>
              onUpdateShipment({ ...shipment, amendmentCode: event.target.value })
            }
            onClick={(event) => event.stopPropagation()}
            size='small'
            value={shipment.amendmentCode || ''}
            variant='outlined'
          >
            <option value="" disabled>
              {translatedTextsObject.amendmentCode}
            </option>
            {amendmentCodeOptions
              .filter(
                (option) =>
                  (shipmentType === 'IN-BOND' && option.code > 15) ||
                  (shipmentType !== 'IN-BOND' &&
                    option.code !== '16' &&
                    option.code !== '17' &&
                    option.code !== '26')
              )
              .map((option) => (
                <option key={option.code} value={option.code}>
                  {option.desc}
                </option>
              ))}
          </Select>
        </Grid>
      )}
      <Grid item xs='auto'>
        <Tooltip
          arrow
          placement='top'
          title={
            isSentStatus
              ? translatedTextsObject.submitSentRecord
              : (shipment.messageFunction === "24" && !shipment.amendmentCode) ? translatedTextsObject.submitEmptyAmendmentCode : translatedTextsObject.submitButton
          }
        >
          <span>
            <CngIconButton
              disabled={isSentStatus || !isSelected || !shipment.messageFunction || (shipment.messageFunction === "24" && !shipment.amendmentCode) || manifestStatus === "1002"}
              size='small'
              icon={['fal', 'arrow-alt-right']}
              onClick={handleSubmitButtonClick}
              type='outlined'
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  )
}